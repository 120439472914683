import { Helmet } from "react-helmet"
import React, { useEffect } from "react"
import success from "../../images/illustrations/success.svg";
import Footer from "../../components/Footer";
import "../../styles/bootstrap-grid.min.css";
import "../../styles/bootstrap-reboot.min.css";
import "../../styles/success.css";
import NavBar from "../../components/NavBar";
import { apiBaseUri, call, getApiUri } from "../../utils/call/Calls";
import { RequestMethod } from "../../enums/RequestMethod";

// markup
const SuccessPage = ({location}:any) => {

  const urlParams = new URLSearchParams(location?.search);
  const email = urlParams.get("email");

  useEffect(() => {
    call(
      RequestMethod.GET,
      getApiUri(`${apiBaseUri()}subscription/confirm`, {
        email: email
      }),
    ).then((data:{
        email: string,
        subscriptionAllowed: boolean
    })=>{
        console.log(data);
    }).catch(err=>{
        console.error('Error', err);
        window.alert("Nastal problém při komunikaci se serverem, zkus to prosím později.");
    });
  }, [])
  
  
  return (
    <main className="body">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Keyword Eater - Šetříme stovky hodin SEO specialistům</title>
        </Helmet>
      <NavBar />
      <div className="container-xl section-succes">
        <div className="row">
            <div className="card">
                <img src={success} alt="illustration success" />
                <h3>
                    Byl jsi úspěšně zaregistrován!
                </h3>
                <p>
                Je to černé na fialovém, budeš <b>jeden z prvních</b>, kteří náš <b>nástroj vyzkouší</b>. Mimo to, že ti přijde pozvánka, se  můžeš těšit na <b>webinář</b> pro naše <b>první uživatele</b>, ve kterém ti vysvětlíme, <b>jak náš nástroj funguje, co vše umí</b> a <b>odpovíme na všechny Tvé otázky</b>. <b>Termín a čas</b> webináře ti zašleme <b>stejně jako pozvánku</b> do <b>mailu</b>. Chceme Ti tak poděkovat za <b>Tvou důvěru</b>. 💜
                </p>
            </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default SuccessPage